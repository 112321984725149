import React, { useState, useEffect } from 'react';
import CalendarViewComponent from './CalendarViewComponent';
import { 
  AIRTABLE_API_KEY, 
  AIRTABLE_BASE_ID, 
  AIRTABLE_GANTTS_TABLE, 
  AIRTABLE_POSTS_TABLE 
} from './airtableConfig';

const SharedGanttView = ({ ganttId, clientId, onApprove, onReject }) => {
  const [ganttData, setGanttData] = useState(null);
  const [postDetails, setPostDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchFromAirtable = async (endpoint, params = {}) => {
    const baseUrl = `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}`;
    const url = `${baseUrl}/${endpoint}`;
    
    const queryParams = new URLSearchParams(params).toString();
    const fullUrl = `${url}${queryParams ? `?${queryParams}` : ''}`;

    try {
      console.log(`Fetching from Airtable: ${fullUrl}`);
      const response = await fetch(fullUrl, {
        headers: {
          'Authorization': `Bearer ${AIRTABLE_API_KEY}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const errorBody = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, body: ${errorBody}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error(`Error fetching from Airtable (${endpoint}):`, error);
      throw error;
    }
  };

  const fetchPostDetails = async (postIds) => {
    try {
      console.log('Fetching posts for IDs:', postIds);
      const posts = {};

      for (const postId of postIds) {
        const data = await fetchFromAirtable(`${AIRTABLE_POSTS_TABLE}/${postId}`);
        console.log(`Raw data fetched for post ${postId}:`, JSON.stringify(data, null, 2));

        posts[postId] = {
          id: data.id,
          title: data.fields.Title,
          description: data.fields.Description,
          scheduledDate: data.fields['Scheduled Date'],
          status: data.fields.Status,
          ganttChart: data.fields['Gantt Chart'] ? data.fields['Gantt Chart'][0] : null,
          client: data.fields.Client ? data.fields.Client[0] : null,
          images: data.fields.Images,
          notes: data.fields.Notes,
          platform: data.fields.Platform
        };
      }

      console.log('Formatted posts:', JSON.stringify(posts, null, 2));
      return posts;
    } catch (error) {
      console.error('Error in fetchPostDetails:', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchGanttData = async () => {
      setIsLoading(true);
      try {
        const gantt = await fetchFromAirtable(`${AIRTABLE_GANTTS_TABLE}/${ganttId}`);
        setGanttData(gantt);
        
        if (gantt.fields.Posts) {
          const posts = await fetchPostDetails(gantt.fields.Posts);
          setPostDetails(posts);
        }
      } catch (error) {
        console.error('Error fetching Gantt data:', error);
        setError('Failed to load Gantt data. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchGanttData();
  }, [ganttId]);

  if (isLoading) {
    return <p>טוען נתונים...</p>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  if (!ganttData) {
    return <p>לא נמצאו נתונים עבור הגאנט המבוקש.</p>;
  }

  return (
    <div>
      <h2>{ganttData.fields.Name}</h2>
      <CalendarViewComponent 
        selectedGantt={{
          id: ganttData.id,
          name: ganttData.fields.Name,
          month: ganttData.fields.Month,
          year: ganttData.fields.Year,
          clientId: clientId,
          posts: ganttData.fields.Posts || [],
          scheduledDate: ganttData.fields['Scheduled Date'],
          platform: ganttData.fields.Platform,
        }}
        posts={postDetails}
        onApprove={onApprove}
        onReject={onReject}
        isAdmin={false}
      />
    </div>
  );
};

export default SharedGanttView;