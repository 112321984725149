import React, { useState, useEffect } from 'react';
import moment from 'moment';
import CalendarViewComponent from './CalendarViewComponent';
import SharedGanttView from './SharedGanttView';
import { 
  AIRTABLE_API_KEY, 
  AIRTABLE_BASE_ID, 
  AIRTABLE_GANTTS_TABLE, 
  AIRTABLE_POSTS_TABLE, 
  AIRTABLE_CLIENTS_TABLE,
  AIRTABLE_API_VERSION 
} from './airtableConfig';

const brandColors = {
  primary: '#007bff',
  secondary: '#28a745',
  accent: '#dc3545',
  background: '#f8f9fa',
  text: '#333333'
};

const brandFonts = {
  primary: 'Arial, sans-serif',
  secondary: 'Georgia, serif'
};

const PostApprovalApp = () => {
  const [ganttEntries, setGanttEntries] = useState([]);
  const [postDetails, setPostDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [selectedClientId, setSelectedClientId] = useState("");
  const [clients, setClients] = useState([]);
  const [error, setError] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [isSharedView, setIsSharedView] = useState(false);
  const [sharedGanttId, setSharedGanttId] = useState(null);
  const [viewerName, setViewerName] = useState('');
  const [isAdmin, setIsAdmin] = useState(true); // You'll need to implement proper admin check
  const [isNameSubmitted, setIsNameSubmitted] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const clientId = urlParams.get('name_of_client');
    const ganttId = urlParams.get('gantt');
    const expires = urlParams.get('expires');

    if (clientId && ganttId && expires) {
      if (moment(expires).isAfter(moment())) {
        setIsSharedView(true);
        setSelectedClientId(clientId);
        setSharedGanttId(ganttId);
      } else {
        setError('This shared link has expired.');
      }
    }
  }, []);

  useEffect(() => {
    const loadClients = async () => {
      if (!AIRTABLE_API_KEY) {
        console.error('Airtable API key is missing');
        setError('Configuration error: Airtable API key is missing');
        return;
      }
      
      setIsLoading(true);
      try {
        const fetchedClients = await fetchClients();
        setClients(fetchedClients);
      } catch (error) {
        console.error('Failed to load clients:', error);
        setError(`Failed to load clients. ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    loadClients();
  }, []);

  useEffect(() => {
    const loadGanttEntries = async () => {
      console.log('loadGanttEntries called, selectedClientId:', selectedClientId);
      if (!selectedClientId) {
        console.log('No client selected, skipping Gantt entries fetch');
        return;
      }
      setIsLoading(true);
      setError(null);
      try {
        console.log('Fetching Gantt entries for client ID:', selectedClientId);
        const fetchedEntries = await fetchGanttEntries(selectedClientId);
        console.log('Fetched Gantt entries:', fetchedEntries);
        setGanttEntries(fetchedEntries);
        setPostDetails({});
      } catch (error) {
        console.error('Failed to load Gantt entries:', error);
        setError('Failed to load data. Please try again.');
        setGanttEntries([]);
        setPostDetails({});
      } finally {
        setIsLoading(false);
      }
    };

    loadGanttEntries();
  }, [selectedClientId]);

  const handleViewerNameSubmit = async (e) => {
    e.preventDefault(); // Prevent form submission from refreshing the page
    if (!viewerName.trim()) {
      alert('נא להזין שם לפני ההגשה');
      return;
    }
    setIsLoading(true);
    try {
      await updateGanttInAirtable(sharedGanttId, {
        'The name of the approving authority': viewerName,
        'date_approving': moment().toISOString()
      });
      setIsNameSubmitted(true);
    } catch (error) {
      console.error('Failed to update viewer information:', error);
      setError('Failed to submit name. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFromAirtable = async (endpoint, params = {}) => {
    const baseUrl = `https://api.airtable.com/${AIRTABLE_API_VERSION}/${AIRTABLE_BASE_ID}`;
    const url = `${baseUrl}/${endpoint}`;
    
    const queryParams = new URLSearchParams(params).toString();
    const fullUrl = `${url}${queryParams ? `?${queryParams}` : ''}`;

    try {
      console.log(`Fetching from Airtable: ${fullUrl}`);
      const response = await fetch(fullUrl, {
        headers: {
          'Authorization': `Bearer ${AIRTABLE_API_KEY}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const errorBody = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, body: ${errorBody}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error(`Error fetching from Airtable (${endpoint}):`, error);
      throw error;
    }
  };

  const fetchClients = async () => {
    try {
      const data = await fetchFromAirtable(AIRTABLE_CLIENTS_TABLE, { 'fields[]': 'Client Name' });
      return data.records.map(record => ({
        id: record.id,
        name: record.fields['Client Name'] || 'Unknown'
      }));
    } catch (error) {
      console.error('Error in fetchClients:', error);
      throw error;
    }
  };

  const fetchGanttEntries = async (clientId) => {
    try {
      console.log(`Preparing to fetch all Gantt entries`);
      
      const data = await fetchFromAirtable(AIRTABLE_GANTTS_TABLE);
      
      console.log(`Received ${data.records.length} Gantt entries`);
      if (data.records.length === 0) {
        console.log('No Gantt entries found in the table');
        return [];
      }
      
      data.records.forEach(record => {
        console.log('Gantt Entry:', JSON.stringify(record, null, 2));
      });
      
      const filteredEntries = data.records.filter(record => {
        console.log(`Entry ${record.id} Client field:`, record.fields.Client);
        return record.fields.Client && record.fields.Client.includes(clientId);
      });
      
      console.log(`Filtered ${filteredEntries.length} entries for client ${clientId}`);
      
      return filteredEntries.map(record => ({
        id: record.id,
        name: record.fields.Name,
        month: record.fields.Month,
        year: record.fields.Year,
        clientId: clientId,
        posts: record.fields.Posts ? record.fields.Posts : [],
        scheduledDate: record.fields['Scheduled Date'],
        platform: record.fields.Platform,
      }));
    } catch (error) {
      console.error('Error in fetchGanttEntries:', error);
      throw error;
    }
  };

  const fetchPostDetails = async (postIds) => {
    try {
      console.log('Fetching posts for IDs:', postIds);
      const posts = [];

      for (const postId of postIds) {
        const data = await fetchFromAirtable(`${AIRTABLE_POSTS_TABLE}/${postId}`);
        console.log(`Raw data fetched for post ${postId}:`, JSON.stringify(data, null, 2));

        const formattedPost = {
          id: data.id,
          title: data.fields.Title,
          description: data.fields.Description,
          scheduledDate: data.fields['Scheduled Date'],
          status: data.fields.Status,
          ganttChart: data.fields['Gantt Chart'] ? data.fields['Gantt Chart'][0] : null,
          client: data.fields.Client ? data.fields.Client[0] : null,
          images: data.fields.Images,
          notes: data.fields.Notes,
          platform: data.fields.Platform
        };

        posts.push(formattedPost);
      }

      console.log('Formatted posts:', JSON.stringify(posts, null, 2));
      return posts;
    } catch (error) {
      console.error('Error in fetchPostDetails:', error);
      throw error;
    }
  };

  const updatePostInAirtable = async (postId, updates) => {
    try {
      const url = `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/${AIRTABLE_POSTS_TABLE}/${postId}`;
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${AIRTABLE_API_KEY}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ fields: updates })
      });
      if (!response.ok) throw new Error('Failed to update post');
      return await response.json();
    } catch (error) {
      console.error('Error updating post in Airtable:', error);
      throw error;
    }
  };

  const updateGanttInAirtable = async (ganttId, updates) => {
    try {
      const url = `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/${AIRTABLE_GANTTS_TABLE}/${ganttId}`;
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${AIRTABLE_API_KEY}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ fields: updates })
      });
      if (!response.ok) throw new Error('Failed to update Gantt');
      return await response.json();
    } catch (error) {
      console.error('Error updating Gantt in Airtable:', error);
      throw error;
    }
  };

  const handleClientSelection = (e) => {
    const clientId = e.target.value;
    console.log('Client selected:', clientId);
    setSelectedClientId(clientId);
  };

  const handleGanttSelection = async (entry) => {
    console.log('Gantt entry selected:', JSON.stringify(entry, null, 2));
    setSelectedEntry(entry);
    setShowCalendar(true);
    setIsLoading(true);
    try {
      const postIds = entry.posts || [];
      console.log('Post IDs from selected Gantt:', postIds);
  
      const posts = await fetchPostDetails(postIds);
      console.log('Fetched posts:', JSON.stringify(posts, null, 2));
  
      const postDetailsMap = posts.reduce((acc, post) => {
        acc[post.id] = { ...post, platform: entry.platform };
        return acc;
      }, {});
      
      console.log('Post details map:', JSON.stringify(postDetailsMap, null, 2));
      setPostDetails(postDetailsMap);
    } catch (error) {
      console.error('Failed to load post details:', error);
      setError('Failed to load post details. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleApprovePost = async (postId, notes, status) => {
    try {
      await updatePostInAirtable(postId, { Status: status, Notes: notes });
      console.log(`Updated post: ${postId} with status: ${status} and notes: ${notes}`);
      setPostDetails(prevDetails => ({
        ...prevDetails,
        [postId]: { ...prevDetails[postId], Status: status, Notes: notes }
      }));
    } catch (error) {
      console.error('Error updating post:', error);
      setError('Failed to update post. Please try again.');
    }
  };

  const handleRejectPost = async (postId, notes) => {
    try {
      await updatePostInAirtable(postId, { Status: 'נדחה', Notes: notes });
      console.log(`Rejected post: ${postId} with notes: ${notes}`);
      setPostDetails(prevDetails => ({
        ...prevDetails,
        [postId]: { ...prevDetails[postId], Status: 'נדחה', Notes: notes }
      }));
    } catch (error) {
      console.error('Error rejecting post:', error);
      setError('Failed to reject post. Please try again.');
    }
  };

  const getClientNameById = (clientId) => {
    const client = clients.find(c => c.id === clientId);
    return client ? client.name : 'Unknown Client';
  };

  if (isSharedView && !isNameSubmitted) {
    return (
      <div style={{ maxWidth: '64rem', margin: '0 auto', padding: '16px', fontFamily: brandFonts.primary, backgroundColor: brandColors.background, direction: 'rtl' }}>
        <h2>הזן את שמך כדי לצפות בלוח הגאנט המשותף</h2>
        <form onSubmit={handleViewerNameSubmit}>
          <input
            type="text"
            value={viewerName}
            onChange={(e) => setViewerName(e.target.value)}
            placeholder="הזן את שמך"
            style={{ padding: '8px', marginRight: '8px' }}
          />
          <button type="submit" style={{ padding: '8px 16px', backgroundColor: brandColors.primary, color: 'white', border: 'none', borderRadius: '4px' }}>
            שלח
          </button>
        </form>
        {isLoading && <p>טוען...</p>}
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>
    );
  }

  return (
    <div style={{ maxWidth: '64rem', margin: '0 auto', padding: '16px', fontFamily: brandFonts.primary, backgroundColor: brandColors.background, direction: 'rtl' }}>
      <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '16px', color: brandColors.text, fontFamily: brandFonts.secondary }}>לוח בקרת פוסטים</h1>
      
      {error && <p style={{ color: 'red' }}>{error}</p>}
      
      {isSharedView && isNameSubmitted ? (
        <SharedGanttView
          ganttId={sharedGanttId}
          clientId={selectedClientId}
          onApprove={handleApprovePost}
          onReject={handleRejectPost}
        />
      ) : (
        <>
          <select 
            value={selectedClientId}
            onChange={handleClientSelection}
            style={{ marginBottom: '16px', padding: '8px', border: `1px solid ${brandColors.primary}`, borderRadius: '4px' }}
          >
            <option value="">בחר לקוח</option>
            {clients.map(client => (
              <option key={client.id} value={client.id}>{client.name}</option>
            ))}
          </select>

      {isLoading && <p>טוען נתונים...</p>}
      
      {!isLoading && ganttEntries.length === 0 && selectedClientId && <p>אין רשומות זמינות ללקוח זה.</p>}
      
      {!isLoading && ganttEntries.length > 0 && (
      <div>
        <p>מספר רשומות: {ganttEntries.length}</p>
        {ganttEntries.map(entry => (
          <div key={entry.id} style={{ marginBottom: '16px', border: '1px solid #ccc', padding: '8px', borderRadius: '4px' }}>
            <h3>{entry.name}</h3>
            <p>חודש: {entry.month} {entry.year}</p>
            <p>לקוח: {getClientNameById(entry.clientId, clients)}</p>
            <p>פוסטים: {entry.posts ? entry.posts.length : 0}</p>
            <p>פלטפורמה: {entry.platform}</p>
            <button onClick={() => handleGanttSelection(entry)} style={{ backgroundColor: brandColors.primary, color: 'white', border: 'none', padding: '4px 8px', borderRadius: '4px', marginTop: '8px' }}>
              הצג בלוח שנה
            </button>
          </div>
        ))}
      </div>
    )}

{showCalendar && selectedEntry && (
            <div style={{ border: '1px solid #ccc', borderRadius: '4px', padding: '16px', marginTop: '16px' }}>
              <h2 style={{ marginBottom: '16px' }}>לוח שנה עבור {selectedEntry.name}</h2>
              <div>
                <CalendarViewComponent 
                  selectedGantt={selectedEntry} 
                  posts={postDetails}
                  onApprove={handleApprovePost}
                  onReject={handleRejectPost}
                  isAdmin={isAdmin}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PostApprovalApp;
