import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

// Platform icons
const platformIcons = {
  facebook: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKiIUD8inGXm1qkoVi1iSQtmS3os4MvXxmAQ&s",
  instagram: "https://upload.wikimedia.org/wikipedia/commons/9/95/Instagram_logo_2022.svg",
  tiktok: "https://sf-static.tiktokcdn.com/obj/eden-sg/uhtyvueh7nulogpoguhm/tiktok-icon2.png",
  linkedin: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRokEYt0yyh6uNDKL8uksVLlhZ35laKNQgZ9g&s"
};

const PostModal = ({ post, onClose, onApprove, onReject, onNext, onPrevious }) => {
  const [notes, setNotes] = useState('');
  const [feedback, setFeedback] = useState('');

  if (!post) return null;

  const handleAction = (action, status) => {
    if (action === 'approve') {
      onApprove(post.id, notes, status);
    } else {
      onReject(post.id, notes, status);
    }
    setFeedback('קיבלנו, תודה!');
    setTimeout(() => {
      setFeedback('');
      onNext();
    }, 1500);
  };

  // Helper function to safely get platform icon
  const getPlatformIcon = (platform) => {
    if (typeof platform === 'string' && platformIcons[platform.toLowerCase()]) {
      return platformIcons[platform.toLowerCase()];
    }
    return null;
  };

  const platformIcon = getPlatformIcon(post.platform);

  return (
    <div style={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
      zIndex: 1000,
      maxWidth: '500px',
      width: '90%'
    }}>
      <button onClick={onClose} style={{ position: 'absolute', top: '10px', left: '10px', background: 'none', border: 'none', fontSize: '20px', cursor: 'pointer' }}>
        ✕
      </button>
      <h2>{post.title}</h2>
      <p>תאריך פרסום מתוכנן: {moment(post.scheduledDate).format('DD/MM/YYYY')}</p>
      {post.platform && (
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <span>פלטפורמה: {post.platform}</span>
          {platformIcon && (
            <img 
              src={platformIcon} 
              alt={post.platform} 
              style={{ width: '24px', height: '24px', marginRight: '5px' }} 
            />
          )}
        </div>
      )}
      {post.images && post.images.length > 0 && (
        <img src={post.images[0].url} alt={post.title} style={{ maxWidth: '100%', marginBottom: '10px' }} />
      )}
      <p>{post.description}</p>
      <textarea
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        placeholder="הוסף הערות כאן"
        style={{ width: '100%', marginBottom: '10px' }}
      />
      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
        <button onClick={onPrevious} style={{ padding: '10px', fontSize: '20px' }}>&#8594;</button>
        <button onClick={() => handleAction('approve', 'מאושר')} style={{ padding: '10px', backgroundColor: 'green', color: 'white', border: 'none', borderRadius: '4px' }}>מאושר בלי הערות</button>
        <button onClick={() => handleAction('approve', 'הערות')} style={{ padding: '10px', backgroundColor: 'blue', color: 'white', border: 'none', borderRadius: '4px' }}>מאושר עם הערות</button>
        <button onClick={() => handleAction('reject', 'נדחה')} style={{ padding: '10px', backgroundColor: 'red', color: 'white', border: 'none', borderRadius: '4px' }}>דחייה</button>
        <button onClick={onNext} style={{ padding: '10px', fontSize: '20px' }}>&#8592;</button>
      </div>
      {feedback && (
        <div style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          backgroundColor: 'green',
          color: 'white',
          padding: '10px',
          borderRadius: '4px'
        }}>
          {feedback}
        </div>
      )}
    </div>
  );
};

const CalendarViewComponent = ({ selectedGantt, posts, onApprove, onReject, isAdmin }) => {
  const [selectedPostIndex, setSelectedPostIndex] = useState(null);
  const [shareLink, setShareLink] = useState('');

  const events = Object.values(posts).map(post => ({
    id: post.id,
    title: post.title || 'Untitled Post',
    start: new Date(post.scheduledDate),
    end: new Date(post.scheduledDate),
    resource: post
  }));

  const handleSelectEvent = (event) => {
    const index = events.findIndex(e => e.id === event.id);
    setSelectedPostIndex(index);
  };

  const handleCloseModal = () => {
    setSelectedPostIndex(null);
  };

  const handleNextPost = () => {
    setSelectedPostIndex((prevIndex) => 
      prevIndex < events.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const handlePreviousPost = () => {
    setSelectedPostIndex((prevIndex) => 
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  const generateShareLink = () => {
    const expirationDate = moment().add(7, 'days').toISOString(); // Set expiration to 7 days from now
    const link = `${window.location.origin}?name_of_client=${encodeURIComponent(selectedGantt.clientId)}&gantt=${encodeURIComponent(selectedGantt.id)}&expires=${expirationDate}`;
    setShareLink(link);
  };

  return (
    <div style={{ height: '500px' }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        onSelectEvent={handleSelectEvent}
        style={{ height: '100%' }}
      />
      {isAdmin && (
        <button onClick={generateShareLink} style={{ marginTop: '10px' }}>Share Gantt</button>
      )}
      {shareLink && (
        <div style={{ marginTop: '10px' }}>
          <input type="text" value={shareLink} readOnly style={{ width: '100%' }} />
          <p>This link will expire in 7 days.</p>
        </div>
      )}
      {selectedPostIndex !== null && (
        <PostModal
          post={events[selectedPostIndex].resource}
          onClose={handleCloseModal}
          onApprove={onApprove}
          onReject={onReject}
          onNext={handleNextPost}
          onPrevious={handlePreviousPost}
        />
      )}
    </div>
  );
};

export default CalendarViewComponent;